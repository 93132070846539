import {FC, useContext} from "react";
import {useParams} from "react-router-dom";
import {Box} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {ModuleNotAvailable} from "@plumeuk/shapeshift-common/module";
import {LessonDataProvider} from "@plumeuk/shapeshift-common/providers";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {LessonPageContent} from "./LessonPageContent";

const useStyles = makeStyles()((theme) => ({
	lessonPage: {
		width: "100%",
		paddingTop: "50px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		},
		"& [ class*='MuiTextField']": {
			border: "none !important",
			padding: "0 0 0 0 !important"
		},
		"& [class*='recentFilter']": {
			position: "relative",
			top: "4px",
			height:"43px",
			marginLeft: "0px",
			borderRadius: "10px",
			border: "0px solid !important"
		},
		"& [class*='wysiwyg-content']": {
			"& iframe": {
				maxWidth: "100%"
			}
		},
		"& [class*='moduleNotAvailable']": {
			"& [class*='-title']": {
				marginBottom: "18px"
			},
			"& [class*='subTitle']": {
				fontSize: "18px"
			},
			"& svg": {
				marginBottom: "18px !important"
			}
		}
	}
}));


export const LessonPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const {moduleSlug: lessonSlug} = useParams();
	const {curriculumState: curriculum} = useContext(CurriculumContext);

	return (
		<Box className={classes.lessonPage}>
			<LessonDataProvider courseSlug={courseSlug} lessonSlug={lessonSlug} curriculum={curriculum} populate={["files", "quiz", "survey"]}>
				{({lesson, apiResult}) => <>
					{apiResult.statusCode === 200 && <LessonPageContent lesson={lesson} courseSlug={courseSlug} lessonSlug={lessonSlug} />}
					{apiResult.statusCode === 403 &&
						<ModuleNotAvailable>
							{apiResult.errorData?.error.message}. We&apos;ll email you when it&apos;s available
						</ModuleNotAvailable>
					}
				</>}
			</LessonDataProvider>
		</Box>
	);
}