import {createContext, ReactElement, Dispatch, FC, useState, useContext} from "react";

const initialState: [PageBaseContextState, Dispatch<React.SetStateAction<PageBaseContextState>>] = [
	{sidebarEnabled: true, sidebarOpen: false, contentContainerRef: undefined},
	() => {}
];
const PageBaseContext = createContext(initialState);

type PageBaseContextState = {
	sidebarEnabled: boolean,
	sidebarOpen: boolean,
	contentContainerRef: any
}

interface IProps {
	children: ReactElement
}

const PageBaseProvider: FC<IProps> = ({children}) => {
	const state = useState<PageBaseContextState>(initialState[0]);

	return (
		<PageBaseContext.Provider value={state}>
			{children}
		</PageBaseContext.Provider>
	);
};

export const usePageBaseContext = (): [PageBaseContextState, Dispatch<React.SetStateAction<PageBaseContextState>>] => {
	const context = useContext(PageBaseContext);
	if (context === undefined) {
		throw new Error("usePageBaseContext must be used within a PageBaseContext");
	}
	return context;
}

export {
	PageBaseContext,
	PageBaseProvider
};